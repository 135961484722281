/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
                var viewportWidth = $(window).width();

                // Fastclick
                FastClick.attach(document.body);

                // Remove empty p tags
                $('p:empty').remove();

                // External links
                $('a').filter(function(){
                    return this.hostname && this.hostname !== location.hostname;
                }).addClass('external').attr('target', '_blank');






                //*Mobile Menu*//
                $( ".c-header__toggle" ).click(function(e) {
                    e.preventDefault();

                    $(".c-header__mobile").slideToggle();
                });

                // for the mobile animation

        $(".c-header__toggle").click(function(){
        $(this).toggleClass("is-active");
        });



            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
                ///Border for Amenities
                document.getElementById('g-border').insertAdjacentHTML('beforebegin',
                    '<img src="/app/themes/sage/dist/images/border.png" alt="border" width="768" height="428" class="g-border alignnone size-full wp-image-128" />');
                    ///Border for Amenities
                    document.getElementById('g-border--white').insertAdjacentHTML('beforebegin',
                        '<img src="/app/themes/sage/dist/images/border2.png" alt="border" width="768" height="428" class="g-border alignnone size-full wp-image-128" />');
                        document.getElementById('g-border--white2').insertAdjacentHTML('beforebegin',
                            '<img src="/app/themes/sage/dist/images/border2.png" alt="border" width="768" height="428" class="g-border alignnone size-full wp-image-128" />');
                            document.getElementById('g-border--white3').insertAdjacentHTML('beforebegin',
                                '<img src="/app/themes/sage/dist/images/border2.png" alt="border" width="768" height="428" class="g-border alignnone size-full wp-image-128" />');
                                document.getElementById('g-border--white4').insertAdjacentHTML('beforebegin',
                                    '<img src="/app/themes/sage/dist/images/border3.png" alt="border" width="768" height="428" class="g-border alignnone size-full wp-image-128" />');

            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
